import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Login from "../views/Login.vue"
import AddList from "../views/AddList.vue"
import DataList from "../views/DataList.vue"
import AddCostumer from "../views/AddCostumer.vue"
import DaftarTugas from "../views/DaftarTugas.vue"
import Test from "../views/Test.vue"
import Test2 from "../views/Test2.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/addlist',
    name: 'AddList',
    component: AddList,
    meta: { requiresAuth: true }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/test2',
    name: 'Test2',
    component: Test2
  },
  {
    path: '/addcostumer',
    name: 'AddCostumer',
    component: AddCostumer,
    meta: { requiresAuth: true }
  },
  {
    path: '/list',
    name: 'DataList',
    component: DataList,
    meta: { requiresAuth: true }
  },
  {
    path: '/list/costumer',
    name: 'DaftarTugas',
    component: DaftarTugas,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      alert("Anda Harus Login terlebih dahulu ya..!!")
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
