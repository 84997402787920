<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary" v-if="userLogin === 'admin'">
      <b-navbar-brand href="#">Buku Komunikasi</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/list">Daftar Komunikasi</b-nav-item>
          <b-nav-item href="/addlist">Input Komplain</b-nav-item>
          <b-nav-item href="/addcostumer">Tambah Kontraktor</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-button variant="danger" @click.prevent="logout()">Logout</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- Navbar Costumer -->

    <b-navbar toggleable="lg" type="dark" variant="primary" v-if="roleLogin === 'costumer'">
      <b-navbar-brand href="#">Buku Komunikasi</b-navbar-brand>
      <b-navbar-brand href="#">{{userLogin}}</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-button variant="danger" @click.prevent="logout()">Logout</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  computed: {
    userLogin() {
      return localStorage.getItem("username");
    },
    roleLogin() {
      return localStorage.getItem("role");
    }
  },

  name: "Navbar",
  methods: {
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
      localStorage.removeItem("role");
      alert("Sampai Jumpa Bos ku");
      this.$router.push("/login");
    }
  }
  // created() {
  //   this.userLogin();
  // }
};
</script>

<style>
</style>