import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../API/axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userlogin: ""
  },
  mutations: {
    setUserLogin(state, data) {
      state.userlogin = data
    }
  },
  actions: {
    login(context, payload) {
      console.log(payload);
      return axios.post(`/login`, {
        username: payload.username,
        password: payload.password
      })
    },
    addList(context, payload) {
      return axios.post("/database", {
        namaPekerjaan: payload.namaPekerjaan,
        UserId: +payload.UserId,
        tanggalPekerjaan: payload.tanggalPekerjaan,
        hariKe: +payload.hariKe,
        subject: payload.subject,
        detail: payload.detail
      }, {
        headers: { access_token: localStorage.getItem("access_token") }
      })
    }

  },
  modules: {
  }
})
