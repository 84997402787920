<template>
  <div class="LoginPage">
    <!-- <h1>Buku Komunikasi</h1> -->
    <img src="../assets/Perumnas.png" alt />
    <form @submit.prevent="login">
      <input type="text" v-model="username" placeholder="username" />
      <input type="password" v-model="password" placeholder="password" />
      <button style="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      let input = {
        username: this.username,
        password: this.password
      };
      this.$store.dispatch("login", input).then(data => {
        console.log(data, "dataaaa");
        alert("Selamat Datang Bossku!!");
        localStorage.setItem("access_token", data.data.access_token);
        localStorage.setItem("username", this.username);
        localStorage.setItem("role", data.data.role);
        console.log(data.data.role);
        this.$store.commit("setUserLogin", data.data.role);
        if (data.data.role == "admin") {
          this.$router.push("/list");
        } else if (data.data.role == "costumer") {
          this.$router.push("/list/costumer");
        }
        // console.log(this.$store.state.userLogin);
      });
    }
  }
};
</script>

<style>
.LoginPage {
  display: grid;
  align-content: center;
  justify-content: center;
  min-height: 80vh;
  /* background-color: aquamarine; */
}
.LoginPage img {
  justify-self: center;
  width: 200px;
}
.LoginPage form {
  display: grid;
  /* justify-items: center; */
  /* justify-content: center; */
}
.LoginPage form input {
  width: 200px;
  text-align: center;
  border: none;
  margin-top: 15px;
}
.LoginPage form button {
  margin-top: 25px;
  justify-self: center;
  width: 150px;
}
@media screen and (max-width: 800px) {
  .LoginPage form button {
    justify-self: center;
    width: 110px;
  }
  .LoginPage form input {
    text-align: center;
    width: 200px;
  }
  .LoginPage {
    min-height: 500px;
  }
}
</style>