<template>
  <div class="DataList">
    <table class="table mt-3">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nama Pekerjaan</th>
          <th scope="col">Tanggal Pengerjaan</th>
          <th scope="col">Hari Ke</th>
          <th scope="col">Subject</th>
          <th scope="col">Detail</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody v-for="list in dataList" :key="list.id" :value="list.id">
        <tr>
          <th scope="row">{{ list.id }}</th>
          <td>{{ list.namaPekerjaan }}</td>
          <td>{{ list.tanggalPekerjaan.slice(0, 10) }}</td>
          <td>{{ list.hariKe }}</td>
          <td>{{ list.subject }}</td>
          <td>{{ list.detail }}</td>
          <td>{{ list.status }}</td>
        </tr>
      </tbody>
    </table>
    <!-- {{this.dataList}} -->
  </div>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "DaftarTugas",
  computed: {
    // userStatus() {
    //     return this.$store.state.
    // }
  },
  data() {
    return {
      dataList: []
    };
  },
  methods: {
    fatchData() {
      return axios
        .get("/database/cost", {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(data => {
          this.dataList = data.data;
        })
        .catch(err => {
          res.status(401).json(err);
          console.log(err);
        });
    },
    confirmStatus(id) {
      let yes = confirm("Yakiin Mengclose?");
      if (yes) {
        this.editStatus(id);
      } else {
        alert("Berhasilkin di batalkan");
      }
    },
    confirmDelete(id) {
      let yes = confirm("Yakiin delete?");
      if (yes) {
        this.deleteList(id);
      } else {
        alert("Berhasilkin di batalkan");
      }
    },
    deleteList(id) {
      return axios
        .delete(`/database/${id}`, {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(data => {
          alert("data telah dihapus");
        })
        .catch(err => {
          res.status(401).json(err);
          console.log(err);
        });
    },
    editStatus(id) {
      return axios
        .patch(
          `/database/status/${id}`,
          {
            status: "close"
          },
          {
            headers: {
              access_token: localStorage.getItem("access_token")
            }
          }
        )
        .then(data => {
          alert(`Data Dengan ID : ${id} Telah Terselesaikan`);
        })
        .catch(err => {
          res.status(401).json(err);
          console.log(err);
        });
    }
  },
  created() {
    this.fatchData();
  }
};
</script>

<style>
.DataList table {
  font-size: 12px;
  /* text-align: left; */
}
.DataList tbody {
  font-size: 14px;
  text-align: left;
}
@media screen and (max-width: 800px) {
  .DataList table {
    font-size: 10px;
    /* text-align: left; */
  }
  .DataList tbody {
    font-size: 8px;
    text-align: left;
  }
  .DataList button {
    /* width: 50px; */
    display: flex;
    font-size: 5px;
  }
  .buttonAction {
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>