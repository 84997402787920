<template>
  <div class="LoginPage">
    <!-- <h1>Buku Komunikasi</h1> -->
    <p>Tambahkan Kontraktor</p>
    <form @submit.prevent="addCostumer">
      <input type="text" v-model="username" placeholder="username" />
      <input type="text" v-model="password" placeholder="password" />
      <button style="submit" class="btn btn-primary">Tambahkan</button>
    </form>
  </div>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "AddCostumer",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    addCostumer() {
      let payload = {
        username: this.username,
        password: this.password,
        role: "costumer"
      };
      return axios
        .post("/register", payload, {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(data => {
          alert("Berhasil Ditambahkan");
          localStorage.getItem("access_token");
          console.log(data);
        });
    }
  }
};
</script>


<style>
.LoginPage p {
  font-family: "Otomanopee One", sans-serif;
  font-size: 25px;
}
.LoginPage {
  display: grid;
  align-content: center;
  justify-content: center;
  min-height: 80vh;
  /* background-color: aquamarine; */
}
.LoginPage img {
  justify-self: center;
  width: 200px;
}
.LoginPage form {
  display: grid;
  justify-items: center;
  /* justify-content: center; */
}
.LoginPage form input {
  width: 200px;
  text-align: center;
  border: none;
  margin-top: 15px;
}
.LoginPage form button {
  margin-top: 25px;
  justify-self: center;
  width: 150px;
}
@media screen and (max-width: 800px) {
  .LoginPage form button {
    justify-self: center;
    width: 110px;
  }
  .LoginPage form input {
    text-align: center;
    width: 200px;
  }
  .LoginPage {
    min-height: 500px;
  }
}
</style>