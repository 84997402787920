<template>
  <b-container class="bv-example-row">
    <b-row align-h="center">
      <!-- Form Head -->
      <b-row align-h="center">
        <b-col>
          <b-row align-h="center">
            <img src="../assets/logoPerumnas.png" id="title-img" />
          </b-row>
          <b-row align-h="center">
            <p id="inputBuku">Input Buku Komunikasi</p>
          </b-row>
        </b-col>
      </b-row>
      <!-- Card -->
      <div>
        <b-row align-h="center">
          <b-form @submit="addList" id="addForm">
            <b-col align-h="center" id="formCard">
              <!-- Form -->
              <b-row>
                <b-col>
                  <b-form-group id="input-group-1" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="namaPekerjaan"
                      type="text"
                      placeholder="Nama Pekerjaan"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label="Tanggal Kontrak:" label-for="input-2">
                    <b-form-input
                      id="input-2"
                      type="date"
                      v-model="tanggalPekerjaan"
                      placeholder="Enter name"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <div class="form-group">
                    <select class="form-control" id="exampleFormControlSelect1" v-model="UserId">
                      <option value selected disabled>Pilih Nama Kontraktor</option>
                      <option
                        v-for="selectList in listCost"
                        :key="selectList.id"
                        :value="selectList.id"
                      >{{selectList.username}}</option>
                    </select>
                  </div>

                  <b-form-group id="input-group-1" label-for="input-1">
                    <b-form-input
                      id="harike"
                      v-model="hariKe"
                      type="number"
                      placeholder="Hari Ke-:"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-for="input-2">
                    <b-form-input id="input-2" v-model="subject" placeholder="Subject" required></b-form-input>
                  </b-form-group>
                  <b-form-textarea
                    id="textarea"
                    label="Detail:"
                    v-model="detail"
                    placeholder="Detail Pengerjaan"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
                <b-col lg="4">
                  <img src="../assets/logoForm.jpeg" alt />
                </b-col>
              </b-row>

              <!-- <pre class="mt-3 mb-0">{{ text }}</pre> -->
            </b-col>
            <b-button type="submit" variant="primary">Submit</b-button>
          </b-form>
          <b-col></b-col>
        </b-row>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "AddList",
  data() {
    return {
      namaPekerjaan: "",
      UserId: "",
      tanggalPekerjaan: "",
      hariKe: "",
      subject: "",
      detail: "",
      listCost: []
    };
  },
  methods: {
    addList() {
      let payload = {
        namaPekerjaan: this.namaPekerjaan,
        UserId: +this.UserId,
        tanggalPekerjaan: this.tanggalPekerjaan,
        hariKe: +this.hariKe,
        subject: this.subject,
        detail: this.detail
      };
      this.$store
        .dispatch("addList", payload)
        .then(data => {
          alert("Data Berhasil Ditambahkan");
          localStorage.getItem("access_token");
          this.$router.push("/list");
        })
        .catch(err => {
          res.status(401).json(err);
        });
    },
    getCostumer() {
      return axios
        .get("/getAllCostumer", {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(res => {
          console.log(res, "ressss");
          // console.log(res, "ressss");
          this.listCost = res.data;
        })
        .catch(err => {
          res.status(410).json(err);
        });
    }
  },
  created() {
    this.getCostumer();
  }
};
</script>

<style>
#addForm {
  /* background-color: aquamarine; */
  margin-top: 30px;
}
#title-img {
  max-width: 400px;
}
form label {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}
form button {
  width: 200px;
  margin-top: 10px;
  margin-right: 3px;
}
#inputBuku {
  font-family: "Otomanopee One", sans-serif;
  font-size: 25px;
}

@media screen and (max-width: 800px) {
  form button {
    margin-top: 4px;
  }
  #title-img {
    max-width: 200px;
  }
  #inputBuku {
    font-size: 18px;
  }
  form label {
    font-size: 10px;
    /* font-weight: bold; */
    text-align: left;
  }
  #addForm img {
    width: 20%;
    /* display: none; */
  }
}
</style>