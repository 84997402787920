<template>
  <b-container class="bv-example-row">
    <b-row align-h="center">
      <!-- Form Head -->
      <b-row align-h="center">
        <b-col>
          <b-row align-h="center">
            <img src="../assets/logoPerumnas.png" id="title-img" />
          </b-row>
          <b-row align-h="center">
            <p id="inputBuku">Input Buku Komunikasi</p>
          </b-row>
        </b-col>
      </b-row>
      <!-- Form -->
      <form @submit="addList">
        <b-row class="justify-content-md-center">
          <!-- Line 1 -->
          <b-row class="justify-content-md-around">
            <b-col lg="5">
              <b-row class="justify-content-md-center">
                <input type="text" placeholder="Nama Pekerjaan" v-model="namaPekerjaan" required />
              </b-row>
            </b-col>
            <b-col b-col lg="5">
              <b-row class="justify-content-md-center">
                <select v-model="UserId">
                  <option value selected disabled>Pilih Nama Kontraktor</option>
                  <option
                    v-for="selectList in listCost"
                    :key="selectList.id"
                    :value="selectList.id"
                  >{{selectList.username}}</option>
                </select>
              </b-row>
            </b-col>
          </b-row>
          <!-- Line 2 -->
          <b-row id="line2" class="justify-content-md-around">
            <b-col lg="5">
              <b-row class="justify-content-md-center">
                <input
                  type="date"
                  placeholder="Tanggal Pekerjaan"
                  v-model="tanggalPekerjaan"
                  required
                />
              </b-row>
            </b-col>
            <b-col b-col lg="5">
              <b-row class="justify-content-md-center">
                <input type="number" placeholder="Hari Ke" v-model="hariKe" />
              </b-row>
            </b-col>
          </b-row>
          <!-- line3 -->
          <b-row id="line3" class="container justify-content-md-center">
            <!-- <b-row class="justify-content-md-center"> -->
            <b-col lg="10">
              <b-row>
                <input type="text" placeholder="subject" v-model="subject" />
              </b-row>
            </b-col>
            <!-- </b-row> -->
          </b-row>
          <!-- Line 4 -->
          <b-row id="line4" class="container justify-content-md-center">
            <!-- <b-row class="justify-content-md-center"> -->
            <textarea
              id="textarea"
              v-model="detail"
              placeholder="Deteail Pengerjaan"
              rows="3"
              max-rows="10"
            ></textarea>
            <!-- </b-row> -->
          </b-row>
        </b-row>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-row>
  </b-container>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "AddList",
  data() {
    return {
      namaPekerjaan: "",
      UserId: "",
      tanggalPekerjaan: "",
      hariKe: "",
      subject: "",
      detail: "",
      listCost: []
    };
  },
  methods: {
    addList() {
      let payload = {
        namaPekerjaan: this.namaPekerjaan,
        UserId: +this.UserId,
        tanggalPekerjaan: this.tanggalPekerjaan,
        hariKe: +this.hariKe,
        subject: this.subject,
        detail: this.detail
      };
      this.$store
        .dispatch("addList", payload)
        .then(data => {
          alert("Data Berhasil Ditambahkan");
          console.log("Masuuuuuuk");
          localStorage.getItem("access_token");
          this.$router.push("/list");
          console.log(data);
        })
        .catch(err => {
          res.status(401).json(err);
        });
    },
    getCostumer() {
      return axios
        .get("/getAllCostumer", {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(res => {
          console.log(res, "ressss");
          // console.log(res, "ressss");
          this.listCost = res.data;
        })
        .catch(err => {
          res.status(410).json(err);
        });
    }
  },
  created() {
    this.getCostumer();
  }
};
</script>

<style>
* #title-img {
  /* margin-bottom: 5vh; */
  /* text-align: center; */
  width: 500px;
  padding: 0;
}
input {
  margin: 0px 20px;
}

#inputBuku {
  font-family: "Otomanopee One", sans-serif;
  font-size: 28px;
}
#line2 {
  margin-top: 10px;
}
#line3 {
  margin-top: 10px;
}
#line4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  #inputBuku {
    font-family: "Otomanopee One", sans-serif;
    font-size: 10px;
  }
  * {
    margin: 5px 0px;
  }
  input {
    width: 180px;
  }
  #title-img {
    max-width: 40vh;
  }
  #line2 {
    margin-top: 0px;
  }
  #line3 {
    margin-top: 0px;
    width: 100%;
  }
}
</style>